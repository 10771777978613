import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import linkResolver from "../utils/linkResolver"
import LocalizedLink from "../components/localizedLink"
import { isExternalLink, isDocumentLink } from "../utils/utils"

const CustomizationPage = ({ data, pageContext: { locale }, location }) => {
  const CustomizationPageData = data.prismic.customizationpage
  return (
    <>
      <SEO
        title={CustomizationPageData.meta_title}
        desc={CustomizationPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: CustomizationPageData.title,
          subtitle: CustomizationPageData.subtitle,
          image: {
            image: CustomizationPageData.main_image,
            sharp: CustomizationPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium" css={customizationPageCss}>
        <div className="container">
          <div className="columns is-centered is-vcentered py-6">
            <div className="column is-5">
              {CustomizationPageData.content_image && (
                <Image
                  sharp={CustomizationPageData.content_imageSharp}
                  image={CustomizationPageData.content_image}
                />
              )}
            </div>
            <div className="column is-5">
              <div className="content">
                <h3 className="title has-text-weight-medium">
                  {CustomizationPageData.content_title &&
                    RichText.asText(CustomizationPageData.content_title)}
                </h3>
                <div className="has-text-weight-light">
                  {CustomizationPageData.content_richtext &&
                    RichText.render(
                      CustomizationPageData.content_richtext,
                      linkResolver
                    )}
                </div>
              </div>
              <div className="columns is-multiline">
                {CustomizationPageData.customize_options.map(option => {
                  return (
                    <div
                      key={option.customize_option_id}
                      className="column is-6-desktop is-12-tablet"
                    >
                      <div className="media">
                        {option.customize_option_image && (
                          <figure className="media-left">
                            <div className="image is-64x64">
                              <Image
                                sharp={option.customize_option_imageSharp}
                                image={option.customize_option_image}
                              />
                            </div>
                          </figure>
                        )}
                        <div className="media-content">
                          <div className="content is-uppercase has-text-weight-semibold">
                            {RichText.render(
                              option.customize_option_content,
                              linkResolver
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {CustomizationPageData.tailor_made_options.map(option => {
            return (
              <div
                key={option.tailor_made_option_id}
                className="columns is-centered is-vcentered row__direction py-6"
              >
                <div className="column is-5">
                  {option.tailor_made_option_image && (
                    <figure className="image is-400x400">
                      <Image
                        sharp={option.tailor_made_option_imageSharp}
                        image={option.tailor_made_option_image}
                      />
                    </figure>
                  )}
                </div>
                <div className="column is-5">
                  <div className="content">
                    <h3 className="title has-text-weight-medium">
                      {RichText.asText(option.tailor_made_option_title)}
                    </h3>
                    <p className="has-text-weight-light">
                      {option.tailor_made_option_content &&
                        RichText.asText(option.tailor_made_option_content)}
                    </p>
                  </div>
                  {option.tailor_made_option_link &&
                    isDocumentLink(option.tailor_made_option_link) && (
                      <LocalizedLink
                        to={option.tailor_made_option_link._meta.uid}
                        className="button is-rounded button__primary has-text-white"
                      >
                        {option.tailor_made_option_button}
                      </LocalizedLink>
                    )}
                  {option.tailor_made_option_link &&
                    isExternalLink(option.tailor_made_option_link) && (
                      <a
                        href={option.tailor_made_option_link.url}
                        target={option.tailor_made_option_link.target}
                        rel="noopener noreferrer nofollow"
                        className="button is-rounded button__primary has-text-white"
                      >
                        {option.tailor_made_option_button}
                      </a>
                    )}
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default CustomizationPage

CustomizationPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const CustomizationQuery = graphql`
  query CustomizationQuery($uid: String!, $locale: String!) {
    prismic {
      customizationpage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        content_title
        content_richtext
        content_image
        content_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        customize_options {
          customize_option_id
          customize_option_content
          customize_option_image
          customize_option_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        tailor_made_options {
          tailor_made_option_id
          tailor_made_option_title
          tailor_made_option_content
          tailor_made_option_button
          tailor_made_option_image
          tailor_made_option_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          tailor_made_option_link {
            _linkType
            ... on PRISMIC__Document {
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
        }
      }
      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`

const customizationPageCss = css`
  .row__direction:first-of-type {
    flex-direction: row-reverse;
  }
`
